<template>
  <div class="dict">
    <h1>数据字典</h1>
    <el-form :inline="true">
      <el-form-item label="字典类型">
        <el-select v-model="searchParam.code" filterable @change="commitFindList()" style="width: 120px;">
          <el-option v-for="dictCodeItem in data.dictCodeList" :key="dictCodeItem.code" :label="dictCodeItem.name" :value="dictCodeItem.code" />
        </el-select>
      </el-form-item>
    </el-form>
    <el-table :data="data.dictList" border height="calc(100vh - 310px)">
      <el-table-column type="index" width="60" />
      <el-table-column label="操作" width="60">
        <template #default="scope">
          <el-link v-if="scope.row.id" type="danger" @click="del(scope.row)">删除</el-link>
          <el-text v-else>新增</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="value" label="字典值">
        <template #default="scope">
          <el-input v-model="scope.row.value" :maxlength="100" @change="save(scope.row, 'value')" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup>
import { reactive } from 'vue'
import { ElMessage } from 'element-plus'
import api from '../../apis/api'

const data = reactive({
  dictCodeList: [],
  dictList: []
})

const searchParam = reactive({
  code: null
})

api.get('/backend/dict/getAllCode').then(res => {
  data.dictCodeList = res.dictCodeList
})

const commitFindList = () => {
  if (!searchParam.code) {
    ElMessage.error('请先选择字典类型')
    return
  }
  data.dictList = []
  api.get('/backend/dict/getByCode', {
    params: {
      code: searchParam.code
    }
  }).then(res => {
    data.dictList = res.dictList
    data.dictList.push({})
  })
}

const save = (row, cname) => {
  const param = { id: row.id }
  param[cname] = row[cname]
  if (!param.id) {
    param.code = searchParam.code
  }
  api.post('/backend/dict/set', param).then(() => {
    commitFindList()
    ElMessage.success('更新成功')
  })
}

const del = (row) => {
  api.post('/backend/dict/del', { id: row.id }).then(() => {
    commitFindList()
    ElMessage.success('更新成功')
  })
}
</script>

<style lang="less"></style>